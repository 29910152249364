<template>
  <div class="playlist">
    <div v-if="!iframeLoaded" class="skeleton-grid">
      <!-- Generate 6 skeleton items -->
      <div v-for="n in 6" :key="n" class="skeleton-item">
        <div class="skeleton-thumbnail"></div>
        <div class="skeleton-content">
          <div class="skeleton-title"></div>
          <div class="skeleton-text"></div>
        </div>
      </div>
    </div>
    <iframe 
      src='https://widgets.sociablekit.com/youtube-playlists/iframe/25354313' 
      frameborder='0' 
      width='100%' 
      height='700'
      @load="iframeLoaded = true"
      :class="{ 'hidden': !iframeLoaded }"
    ></iframe>
  </div>
</template>

<style scoped>
.playlist {
  margin-top: 82.84px !important;
}

.skeleton-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  height: 700px;
  overflow-y: auto;
}

.skeleton-item {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.skeleton-thumbnail {
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeleton-content {
  padding: 12px;
}

.skeleton-title {
  height: 20px;
  margin-bottom: 8px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

.skeleton-text {
  height: 16px;
  width: 60%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

.hidden {
  display: none;
}

@keyframes shimmer {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}
</style>

<script setup>
import { ref, onMounted } from 'vue';
import { setDocumentTitle } from '@/utils/utils';

const iframeLoaded = ref(false);

onMounted(() => {
  setDocumentTitle('المكتبة المرئية');
});
</script>
