<template>
  <div class="Media">
    <!-- <Breadcrumb /> -->
    <div class="parentSeries margin-top-82">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-4">
            <AlbumView :media="albums" v-if="albums" />
            <!-- Loading skeleton -->
            <div class="skeleton-loader" v-else>
              <div class="headerSeries row">
                <div class="imagecontainer col-lg-6">
                  <div class="skeleton-img"></div>
                </div>
                <div class="description col-lg-6">
                  <!-- <div class="skeleton-title"></div> -->
                  <div class="skeleton-line"></div>
                  <div class="skeleton-text"></div>
                  <div class="skeleton-line"></div>
                  <div class="skeleton-info"></div>
                </div>
              </div>
              <div class="skeleton-grid">
                <div class="skeleton-item" v-for="n in 4" :key="n"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import AlbumView from '@/components/AlbumView.vue'
import { ref, onMounted } from 'vue'
import { ApiClient, AlbumDTO, MediaControllerApi } from '@/api/index.js'
import { useRoute, useRouter } from 'vue-router';
import { setDocumentTitle } from '@/utils/utils';
const albums = ref(null)
const apiClient = new ApiClient();
const mediaApi = new MediaControllerApi(apiClient);

const route = useRoute()
const router = useRouter()

function getMedia() {
  mediaApi.getMediaById(route.params.id, (error, data, response) => {
    if (error) {
      router.push("/404");
      // console.log(error);
    } else {
      parseAlbums(data);
    }
  });  
};

function parseAlbums(data) {
  albums.value = AlbumDTO.constructFromObject(data);
  setDocumentTitle(albums.value.title);
}

onMounted(() => {
  getMedia();
})
</script>
<style scoped>
.margin-top-82 {
  margin-top: 105.84px !important;
}

/* Skeleton Loading styles */
.skeleton-loader {
  background: var(--section-background);
  padding: 20px;
}

.skeleton-img {
  width: 100%;
  height: 200px;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: shimmer 1.5s linear infinite;
  margin: 30px 10px;
}

.skeleton-title {
  height: 32px;
  width: 70%;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: shimmer 1.5s linear infinite;
  margin-bottom: 15px;
}

.skeleton-line {
  height: 3px;
  width: 10%;
  background: #ececec;
  margin: 15px 0;
}

.skeleton-text {
  height: 100px;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: shimmer 1.5s linear infinite;
}

.skeleton-info {
  height: 80px;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: shimmer 1.5s linear infinite;
  margin-top: 15px;
}

.skeleton-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.skeleton-item {
  height: 200px;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: shimmer 1.5s linear infinite;
  border-radius: 15px;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
