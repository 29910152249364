<template>
  <div class="Media">
    <breadcrumb :imgUrl="img"/>
    <div class="container my-4">
      <div class="row">
        <div class="col-12">
          <div class="title-wrapper">
            <h1 class="category-title">{{title}}</h1>
          </div>
        </div>
      </div>
    </div>
    <Bubbles :categories="childCategories"/>
    <div class="parenSeries">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12">
            <h1 style="color: var(--scond-color); font-weight: 700; font-size: 2rem;">{{title}}</h1>
          </div> -->
          <!-- Display the message when there are no results -->
          <div v-if="articles.length === 0" class="col-12 text-center mt-4">
            <p style="font-size: 1.2rem; color: var(--scond-color);">{{ msg }}</p>
          </div>
          <cardVue  v-if="!isMediaCategory" v-for="article in articles" :key="article.id" :article="article"
           class="col-sm-6 col-lg-4" />
           <cardVue v-if="isMediaCategory" v-for="media in articles" :key="media.id" :media="media" :mediaType="mediaType"
           class="col-sm-6 col-lg-4" />
        </div>
      </div>
    </div>
    <Pagination 
      v-if="totalPages>1"
      :totalPages="totalPages" 
      :currentPage="currentPage" 
      @page-changed="handlePageChange" 
    />
  </div>
</template>

<script setup>
import Bubbles from '../components/Bubbles.vue'
import { useRoute, useRouter } from 'vue-router';
import cardVue from '@/components/cardVue.vue'
import breadcrumb from '@/components/breadcrumb.vue'
import Pagination from '@/components/Pagination.vue'
import {ApiClient, CategoryControllerApi,MediaControllerApi,ArticleControllerApi,CategoryDTO} from "@/api/index.js"
import {ref,onMounted} from 'vue';
import { setDocumentTitle } from '@/utils/utils';

const route = useRoute();

const apiClient = new ApiClient();
const categoryControllerApi = new CategoryControllerApi(apiClient);
const articleControllerApi = new ArticleControllerApi(apiClient);
const mediaApi = new MediaControllerApi(apiClient);
const isParentCategory=ref(false);
const isMediaCategory=ref(false);
const childCategories = ref([]);
const articles=ref([]);
const img=ref('https://i.imgur.com/cykExNN.png');
const mediaType=ref(0);
const totalElements = ref(0);
const pageSize = ref(9);
const currentPage = ref(0);
const totalPages = ref(0);

const title=ref("");
const msg = ref("جاري التحميل..."); // Loading message

const router = useRouter();

function fetchCategoryInfo(){
try{

  isParentCategory.value=!route.params.id.includes('-');
  if(isParentCategory.value==true){
    isMediaCategory.value=false;
    totalPages.value=0;
    var id=route.params.id;
    categoryControllerApi.getCategoryById(id,(error,data,response)=>{
      if(!error && data){
        parseCategory(data);
      }else{
        router.push("/404");
        console.log(error);
      }
    });
    categoryControllerApi.getChildCategories(id,(error,data,response)=>{
      if(!error){
        childCategories.value=data;
      }else{
        // console.log(error);
      }
    });
    if(id!="photos"&&id!="audios"){
      articleControllerApi.getTop5ArticlesByCategory(id,1,false,(error,data,response)=>{
        if(!error){
          articles.value=data;
          if(articles.value.length === 0) {
            msg.value = "لا يوجد منشورات بعد";
          }
        }else{
          // console.log(error);
          msg.value = "حدث خطأ أثناء تحميل المنشورات";
        }
      });
    }else{
      isMediaCategory.value=true;
      if(id=="photos"){
        mediaType.value=0;
        img.value="https://i.imgur.com/cykExNN.png";
        title.value="الصور";
        mediaApi.getTop5MediaByCategory(id,1,false,(error,data,response)=>{
          if(!error){
            articles.value=data;
            if(articles.value.length === 0) {
              msg.value = "لا يوجد وسائط بعد";
            }
          }else{
            console.log(error);
            msg.value = "حدث خطأ أثناء تحميل الوسائط";
          }
        });
      }
      if(id=="audios"){
        img.value="https://i.imgur.com/cykExNN.png";
        title.value="الصوت";
        mediaType.value=1;
        mediaApi.getTop5MediaByCategory(id,1,false,(error,data,response)=>{
          if(!error){
            articles.value=data;
            if(articles.value.length === 0) {
              msg.value = "لا يوجد وسائط بعد";
            }
          }else{
            console.log(error);
            msg.value = "حدث خطأ أثناء تحميل الوسائط";
          }
        });
      }
    }
  }else{
    // isMediaCategory.value=false;
    categoryControllerApi.getCategoryById(route.params.id,(error,data,response)=>{
      if(error)
      { 
        router.push("/404");
      }
      else{  
        parseCategory(data);
        isMediaCategory.value=data.isMediaCategory;
        if(isMediaCategory.value){
          mediaApi.getMediaByCategory(route.params.id,data.parent.nameAr=="audio"?0:1,1,
          {page:currentPage.value,size:pageSize.value},(error,data,response)=>{
            if(error){
              console.log(error);
              msg.value = "حدث خطأ أثناء تحميل الوسائط";
            }else{
              parseArticles(data);
            }
          });
        }else{
          articleControllerApi.getArticlesByCategory(route.params.id,1,{page:currentPage.value==0?0:currentPage.value-1,size:pageSize.value},(error,data,response)=>{
            if(error){
              // console.log(error);
              msg.value = "حدث خطأ أثناء تحميل المنشورات";
              router.push("/404");
            }else{
              if(data.length!=0){        
                console.log(data);
                    
              parseArticles(data);
            }else{
              router.push("/404");
            }
            }
          });
        }
      }
    });

    
  }
}catch(e){
  router.push("/404");
}

}

function parseCategory(data){
  let cat=CategoryDTO.constructFromObject(data);
  img.value=cat.imgCover|| 'https://i.imgur.com/cykExNN.png';
  title.value=cat.nameAr;
  setDocumentTitle(cat.nameAr);
  console.log("nameAr",cat.nameAr);
}

function parseArticles(data){
  articles.value = data.content;
  currentPage.value = data.pageable.pageNumber+1;
  pageSize.value = data.pageable.pageSize;
  totalElements.value = data.totalElements;
  totalPages.value =data.totalPages;
  if(articles.value.length === 0){
    msg.value = "لا يوجد منشورات بعد";
  }
}

function handlePageChange(page) {
  currentPage.value = page;
  // Update the URL with the new page number
  router.push({ query: { ...route.query, page: page } });
  fetchCategoryInfo();
}

onMounted(() => {
  // Check if there's a page number in the URL and set it
  const pageFromQuery = Number(route.query.page);
  if (pageFromQuery && !isNaN(pageFromQuery)) {
    currentPage.value = pageFromQuery;
  }
  fetchCategoryInfo();
});
</script>

<style scoped>
.title-wrapper {
  text-align: center;
}

.category-title {
  color: var(--scond-color);
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
}

.category-title::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 20%;
  width: 60%;
  height: 3px;
  background-color: var(--scond-color);
  border-radius: 2px;
}
</style>
