<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { getDatabase, ref as dbRef, get } from 'firebase/database';

const props = defineProps({
  articleId: {
    type: String,
    required: true
  },
  iconColor: {
    type: String,
    default: '#d06106'
  },
  textColor: {
    type: String,
    default: '#999999'
  }
});

const viewCount = ref(0);
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds
let fetchInterval;

function getCacheKey() {
  return `${props.articleId}_counter`;
}

function getCachedData() {
  const cached = localStorage.getItem(getCacheKey());
  if (cached) {
    const { value, timestamp } = JSON.parse(cached);
    const now = Date.now();
    if (now - timestamp < CACHE_DURATION) {
      return value;
    }
  }
  return null;
}

function setCachedData(value) {
  const cacheData = {
    value,
    timestamp: Date.now()
  };
  localStorage.setItem(getCacheKey(), JSON.stringify(cacheData));
}

async function fetchViewCount() {
  // Try to get cached value first
  const cachedValue = getCachedData();
  if (cachedValue !== null) {
    viewCount.value = cachedValue;
    return;
  }

  // If no valid cache, fetch from Firebase
  const db = getDatabase();
  const viewsRef = dbRef(db, `articleViews/${props.articleId}/count`);
  const snapshot = await get(viewsRef);
  const newCount = snapshot.val() || 0;
  
  viewCount.value = newCount;
  setCachedData(newCount);
}

function startPeriodicFetch() {
  fetchViewCount(); // Initial fetch
  fetchInterval = setInterval(fetchViewCount, CACHE_DURATION);
}

onMounted(() => {
  startPeriodicFetch();
});

onUnmounted(() => {
  if (fetchInterval) {
    clearInterval(fetchInterval);
  }
});

function getViewText(count) {
  if (count === 0) return 'مشاهدة';
  if (count === 1) return 'مشاهدة';
  if (count === 2) return 'مشاهدتان';
  if (count >= 3 && count <= 10) return 'مشاهدات';
  return 'مشاهدة';
}
</script>

<template>
  <div class="view-counter">
    <i class="fa-regular fa-eye" :style="{ color: iconColor }"></i>
    <span :style="{ color: textColor }">{{ viewCount }} {{ getViewText(viewCount) }}</span>
  </div>
</template>

<style scoped>
.view-counter {
  display: flex;
  align-items: center;
  gap: 5px;
}

.view-counter i {
  margin: 0 3px;
}
</style> 