<template>
  <div class="not-found">
    <div class="error-container">
      <h1>404</h1>
      <h2>الصفحة غير موجودة</h2>
      <p>عذرًا، الصفحة التي تبحث عنها غير موجودة. يرجى التحقق من العنوان والمحاولة مرة أخرى.</p>
      <router-link to="/" class="btn btn-primary">العودة إلى الصفحة الرئيسية</router-link>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { setDocumentTitle } from '@/utils/utils';

onMounted(() => {
  setDocumentTitle('الصفحة غير موجودة');
});
</script>

<style scoped>
.not-found {
  min-height: calc(100vh - 82.84px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--section-background);
  padding: 2rem;
  margin-top: 82.84px;
}

.error-container {
  text-align: center;
  background-color: white;
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 90%;
}

h1 {
  color: var(--main-color);
  font-size: 4rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

h2 {
  color: var(--scond-color);
  font-size: 2rem;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 2rem;
  color: var(--main-color);
}

.btn-primary {
  background-color: var(--main-color);
  border-color: var(--main-color);
  color: white;
  padding: 0.75rem 1.5rem;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  display: inline-block;
  border-radius: 0.25rem;
}

.btn-primary:hover {
  background-color: var(--scond-color);
  border-color: var(--scond-color);
  color: var(--main-color);
}
</style> 